import React from "react"

export class OpeningTimes extends React.Component<{}, {}>
{
    constructor(props: any)
    {
        super(props);
    }

    public render(): JSX.Element
    {
        return (
            <>
                {/* <h5 className="text-uppercase">Christmas Opening times</h5>
                <p>
                    Christmas Day - Closed <br />
                    Boxing Day - Open as normal<br />
                    New Years Eve / Day - Open as normal<br />
                </p> */}

                <h5 className="text-uppercase">Opening times</h5>
                <small>
                    Note: For delivery &amp; collection times, <strong>
                        <a href="https://order.orientalgardenrestaurant.co.uk/northampton-chinese-takeaway/menus"
                            title="Click here for delivery & collection times">click here</a>.
                    </strong>
                </small>

                <div className="opening-times">
                    <div className="col">
                        <p>
                            <strong>Monday - Tuesday (12pm - 10pm)</strong><br />
                            <strong>Wednesday - Closed</strong><br />
                            <strong>Thursday -  Sunday (12pm - 10pm)</strong><br />
                        </p>
                    </div>
                </div>
            </>
        );
    }
}