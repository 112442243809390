import { Link } from "gatsby"
import React from "react"

export interface IHeaderProps
{
    siteTitle: string;
    logoSrc: string;
}

interface IHeaderState
{
    mobileNavOpen: boolean;
}

export class Header extends React.Component<IHeaderProps, IHeaderState>
{
    constructor(props: IHeaderProps)
    {
        super(props);
        this.state = {
            mobileNavOpen: false
        }
    }

    private onToggleMobileMenu(): void
    {
        this.setState({
            mobileNavOpen: !this.state.mobileNavOpen
        });
    }

    public render(): JSX.Element
    {
        return (
            <>
                <header className={"site-header" + (this.state.mobileNavOpen ? " mobile-nav-open" : "")}>
                    <div className="content">
                        <div className="logo">
                            <Link to="/">
                                <img src={this.props.logoSrc} alt="Logo" />
                            </Link>
                        </div>
                        <nav>
                            <div>
                                <Link to="/" activeClassName="active">Home</Link>
                                <Link to="/menus" activeClassName="active">Our Food &amp; Menus</Link>
                                <Link to="/book" activeClassName="active">Book a Table</Link>
                                <a href="https://order.orientalgardenrestaurant.co.uk/northampton-chinese-takeaway/menus" title="Order online for delivery and pickup">Order Online</a>
                            </div>
                            {this.state.mobileNavOpen ?
                                <button className="btn btn-menu-close" onClick={this.onToggleMobileMenu.bind(this)}>
                                    <i className="icon icon-close icon-sm"></i>
                                </button>
                                : null
                            }
                        </nav>
                        {!this.state.mobileNavOpen ?
                            <button className="btn btn-menu hamburger" onClick={this.onToggleMobileMenu.bind(this)}>
                                <span className="hamburger-container">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>
                            : null
                        }
                    </div>
                </header>
            </>
        );
    }
}