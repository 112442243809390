import React from "react"
import { Modal } from "./Modal";
import { Link } from "gatsby";
import Cookies from "js-cookie";

export interface IGDPRCookieProps
{
}

interface IGDPRCookieState
{
    showCookieNotice: boolean;
    showCookieModal: boolean;
    analyticsChecked: boolean;
}

export class GDPRCookie extends React.Component<IGDPRCookieProps, IGDPRCookieState>
{
    private static COOKIE_CONSENT: string = "cookie_consent";
    private static COOKIE_CHOICE_GIVEN: string = "cookie_choice_given";

    private analyticsRef: any;

    constructor(props: IGDPRCookieProps)
    {
        super(props);

        this.analyticsRef = React.createRef();

        this.state = {
            showCookieModal: false,
            showCookieNotice: false,
            analyticsChecked: true,
        }
    }

    public componentDidMount(): void
    {
        let cookieChoice: string | undefined = Cookies.get(GDPRCookie.COOKIE_CHOICE_GIVEN);
        let cookieConsent: string | undefined = Cookies.get(GDPRCookie.COOKIE_CONSENT);

        // Hide accept cookie notice
        if (cookieChoice != null)
        {
            this.setState({
                showCookieNotice: false
            });
        }
        else
        {
            this.setState({
                showCookieNotice: true
            });
        }

        // Detect if we're allowed analytics
        if (cookieConsent != null && cookieConsent == "accepted")
        {
            this.addAnalyticsToDOM();
        }
    }

    private onAcceptCookies(): void
    {
        this.setState({
            showCookieModal: false,
            showCookieNotice: false
        });

        Cookies.set(GDPRCookie.COOKIE_CONSENT, this.state.analyticsChecked == true ? "accepted" : "declined", { expires: 31 });
        Cookies.set(GDPRCookie.COOKIE_CHOICE_GIVEN, "true", { expires: 31 });
    }

    private onConfigureCookies(): void
    {
        this.setState({
            showCookieModal: !this.state.showCookieModal,
            showCookieNotice: false
        });
    }

    private onCloseCookieSettings(): void
    {
        this.setState({
            showCookieModal: !this.state.showCookieModal
        });
    }

    private onAnalyticsToggled(): void
    {
        this.setState({
            analyticsChecked: !this.state.analyticsChecked
        });
    }

    private addAnalyticsToDOM(): void
    {
        let ga_script = document.createElement("script");
        ga_script.src = "https://www.googletagmanager.com/gtag/js?id=UA-155237180-1";
        ga_script.async = true;

        let ga_fn = document.createElement("script");
        ga_fn.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-155237180-1');
        `;

        this.analyticsRef.current.appendChild(ga_script);
        this.analyticsRef.current.appendChild(ga_fn);
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Modal
                    title={"Cookie Settings"}
                    open={this.state.showCookieModal}
                    onCloseClicked={this.onCloseCookieSettings.bind(this)}>
                    <p>
                        Please check your cookie settings below and toggle any cookies you're happy with.
                        You can read our full <Link to={"cookie-policy"}>Cookie Policy here</Link>.
                    </p>

                    <div className="form">

                        <label>
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={this.state.analyticsChecked}
                                onChange={this.onAnalyticsToggled.bind(this)}
                            />

                            Google Analytics

                            <div style={{ marginTop: "15px" }}>
                                <small>
                                    Google Analytics helps us to understand how you use the site and ways that we can improve your experience.
                                    These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue
                                    to produce engaging content.
                                </small>
                            </div>
                        </label>
                    </div>

                    <div className="btn-cta">
                        <button className="btn btn-primary" onClick={this.onAcceptCookies.bind(this)}>Save settings</button>
                    </div>

                </Modal>
                {this.state.showCookieNotice ?
                    <div className="gdpr-cookie-notice">
                        <div className="container">
                            <p>
                                We use cookies to give you the best online experience.
                                Please let us know if you agree to all of these cookies.
                            </p>
                            <div className="btn-cta">
                                <button className="btn btn-outline btn-white" onClick={this.onConfigureCookies.bind(this)}>
                                    Cookie Settings
                                </button>
                                <button className="btn btn-primary" onClick={this.onAcceptCookies.bind(this)}>
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>
                    : null
                }

                <div ref={this.analyticsRef}></div>
            </>
        );
    }
}