import { graphql, StaticQuery, Link } from "gatsby"
import React, { ReactNode } from "react"
import { Header } from "./Header";
import "../assets/stylesheets/app.scss";
import { GDPRCookie } from "./GDPRCookie";
import { OpeningTimes } from "./OpeningTimes";

export interface ILayoutProps
{
    children: ReactNode;
    className: string;
}

export class Layout extends React.Component<ILayoutProps, {}>
{
    constructor(props: ILayoutProps)
    {
        super(props);
    }

    public render(): JSX.Element
    {
        return (
            <>
                <StaticQuery query={
                    graphql`
                        query SiteLayout {
                            site {
                                siteMetadata {
                                title
                                }
                            }
                            file(relativePath: { eq: "logo.png" }) {
                                childImageSharp {
                                    fixed(width:103, height: 92) {
                                        src
                                    }
                                }
                            }
                        }
                    `}
                    render={data => (
                        <>
                            {/* <a className="important-announcement" href="/book" title="Book a table" style={{ display: "flex" }}>
                                <span>
                                    <strong>We are open as normal on Monday 19th September</strong> {" - "}
                                    <span className="highlight">Click here to book a table</span>
                                </span>
                            </a> */}

                            <div className={"page page-" + (this.props.className != null ? this.props.className : "default")}>
                                <Header siteTitle={data.site.siteMetadata.title} logoSrc={data.file.childImageSharp.fixed.src} />
                                <main role="main">{this.props.children}</main>
                                <footer role="contentinfo">

                                    <div className="content">
                                        <div className="footer-content">
                                            <div>
                                                <h5 className="text-uppercase">Contact us</h5>
                                                <address>
                                                    <p>
                                                        <a href="tel:01604 602818" title="Call us on 01604 602818">
                                                            <i className="icon icon-phone"></i> - 01604 602818
                                                        </a>
                                                    </p>

                                                    <p>
                                                        <a href="mailto:info@orientalgardenrestaurant.co.uk" title="Email Oriental Garden Northampton">
                                                            <i className="icon icon-email"></i> - Contact us via email
                                                        </a>
                                                    </p>
                                                </address>
                                            </div>

                                            <div className="social-links">
                                                <div className="logo">
                                                    <Link to="/" title="Home">
                                                        <img src={data.file.childImageSharp.fixed.src} alt="Logo" />
                                                    </Link>
                                                </div>
                                                <div className="inline-links">
                                                    <a href="https://www.tripadvisor.co.uk/Restaurant_Review-g186349-d2080039-Reviews-Oriental_Garden-Northampton_Northamptonshire_England.html"
                                                        target="_blank"
                                                        title="Oriental Garden Trip Advisor"
                                                        rel="noopener">
                                                        <i className="icon icon-tripadvisor icon-sm" aria-label="Trip Advisor Icon"></i>
                                                    </a>
                                                    <a href="https://www.facebook.com/Oriental-Garden-Restaurant-Northampton-115224851870649/"
                                                        target="_blank"
                                                        title="Oriental Garden Facebook"
                                                        rel="noopener">
                                                        <i className="icon icon-facebook icon-sm" aria-label="Facebook Icon"></i>
                                                    </a>
                                                    <a href="https://www.instagram.com/orientalgardennorthampton/"
                                                        target="_blank"
                                                        title="Oriental Garden Instagram"
                                                        rel="noopener">
                                                        <i className="icon icon-instagram icon-sm" aria-label="Instagram Icon"></i>
                                                    </a>
                                                </div>

                                                <OpeningTimes />
                                            </div>

                                            <div>
                                                <h5 className="text-uppercase">Find us</h5>
                                                <address>
                                                    <p>
                                                        Oriental Garden <br />
                                                        13-15 Sheep Street<br />
                                                        Northampton<br />
                                                        NN1 2LT<br />
                                                    </p>
                                                </address>
                                            </div>
                                        </div>
                                    </div>
                                </footer>
                                <div className="small-print">
                                    <div className="content">
                                        <p>
                                            Oriental Garden Northampton - &copy; {new Date().getFullYear()}
                                        </p>
                                        <p>
                                            Oriental Garden Restaurant is owned by Oriental Garden China Ltd. Oriental Garden China Ltd is registered in England &amp; Wales.
                                            Company registration no. 06329468. Registered office address: 13-15 Sheep Street, Northampton, Northamptonshire NN1 2LT.
                                            VAT registration no. 924724127.
                                        </p>
                                        <div className="footer-links">
                                            <Link to="privacy-policy">Privacy Policy</Link>
                                            <Link to="cookie-policy">Cookie Policy</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <GDPRCookie />
                        </>
                    )}
                />
            </>
        );
    }
}