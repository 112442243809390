import React from "react";

export interface IModalProps
{
    open: boolean;
    title?: string;
    modalSize?: ModalSize;
    onCloseClicked: Function;
}

export interface IModalState
{

}

export enum ModalSize
{
    Small = "modal-sm",
    Medium = "modal-md",
    Large = "modal-lg"
}

export class Modal extends React.Component<IModalProps, IModalState>
{
    constructor(props: IModalProps)
    {
        super(props);

        this.state = {

        };
    }

    public componentDidUpdate(): void
    {
        var body = document.querySelector("body");

        if (this.props.open && body)
        {
            body.classList.add("modal-bg");
        }
        else if (!this.props.open && body)
        {
            body.classList.remove("modal-bg");
        }
    }

    public render(): JSX.Element
    {
        return (
            <div className={"modal modal-" + (this.props.open ? "open" : "close") + " " + (this.props.modalSize ? this.props.modalSize : ModalSize.Medium)}>
                <div className="modal-header">
                    {
                        this.props.title != null ?
                            <h3>{this.props.title}</h3>
                            : ""
                    }

                    <button className="btn btn-transparent btn-menu-close" onClick={this.props.onCloseClicked.bind(this)}>
                        <i className="icon icon-close icon-sm"></i>
                    </button>
                </div>
                <div className="modal-body">
                    {
                        this.props.open ?
                            this.props.children
                            : null
                    }
                </div>
            </div>
        );
    }

    public componentWillUnmount(): void
    {

    }
}
